<template>

    <div  class="main">
      <div class="lists">

            <div class="img">
               <img v-lazy="photo" ref="shopImg" v-if="photo" alt="">
               <img src="../../../public/images/abai.jpg" v-else alt="">
            </div>
            <div class="introduce">
              <p><span>{{typeItem}}</span>{{title}}</p>

            </div>
            <div class="pri">
              <p><span>￥</span>{{price}}</p>
              <div class="imm">
                立即查看
              </div>
            </div>

      </div>

    </div>

</template>

<script>
export default {

  props: {
    id: {
      Type: Number,
      request: true
    },
    title: {
      Type: String,
      default: ''
    },
    content: {
      Type: Number,
      default: 0
    },
    pubdata: {
      Type: String,
      default: ''
    },
    tel: {
      Type: String,
      default: ''
    },
    price: {
      Type: Number,
      default: ''
    },
    photo: {
      Type: Number,
      default: ''
    },
    type: {
      Type: Number,
      default: ''
    },
    view: {
      Type: Number,
      default: ''
    }

  },
  data () {
    return {
      types: ['无种类', '电子用品', '日常百货', '学习用品', '运动户外', '文化娱乐', '食品保健', '服饰鞋包'],
      typeItem: ''
    }
  },
  methods: {
    initType () {
      // console.log(this.type)
      for (var index in this.types) {
        if (parseInt(index) === this.type) {
          this.typeItem = this.types[index]
        }
        // console.log(this.types[index])
        // console.log(index)
      }
    }
  },
  created () {
    this.initType()
  }
}
</script>

<style lang="less" scoped>

</style>
