<template>
  <div class="trade">
    <div style="min-height:92.9vh">
    <Head></Head>
      <div class="title">
        <h2>二手商品</h2>
      </div>

      <div  class="main" v-if="isShow" >
        <div class="lists">

        <ul>

          <tradeInfo v-for="(item,index) in trades" :key="index"
                :id = item.id
                :photo = item.photo
                :title = item.title
                :content = item.content
                :pubdata = item.pubdata
                :likes = item.likes
                :view = item.view
                :type = item.types
                :tel = item.tel
                :price = item.price
                @mouseenter="imgUp" @mouseleave="imgDown"
                class="tradeInfo"
                @click.native="$router.push('/trade/'+item.id,{params:{id:item.id}})"
            ></tradeInfo>

        </ul>

    </div>
   </div>
     <el-empty class="el-empty" v-else :image-size="200"></el-empty>
  </div>

   <!-- <router-view @showSchInfo="showSch"></router-view> -->
   <Foot></Foot>
  </div>
</template>

<script>

import head from '../head.vue'
import { getTrade } from '../../API/Trade.js'
import Foot from '../../views/foot.vue'
import tradeInfo from './TradeInfo.vue'
export default {
  name: 'Trade',

  data () {
    return {
      trades: [],
      isShow: true,
      loading: false, // 控制加载指示器的显示
      loadingText: '加载中...', // 自定义加载文本

    }
  },
  methods: {
    // 获取所有二手商品信息
    showSch (e) {
      // console.log(e)
    },
    async getTrades () {
      this.loading = true
      const { data: res } = await getTrade()
      if (res.status === 0) {
        this.loading=false
        res.data.map((item, index, arr) => {
          // 已发布列表(包含既未下架也未删除)
          if (item.isdelete === 0 && item.isdown === 0) {
            this.trades = [...this.trades, item]
          }
        })
      }
      // console.log(this.trades.length)
      if (this.trades.length !== 0) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    },
    imgUp (e) {
      // console.log(e)
      this.$refs.shopImg.style = 'transform: translateY(-15px);'
    },
    imgDown (e) {
      // console.log(e + '1')
      this.$refs.shopImg.style = 'transform: translateY(0px);'
    },
    loadingShow() {
      let loading = this.$loading({
        text: "Loading",  // 显示文本
        spinner: "el-icon-loading", // 显示的 icon
        background: "rgba(0, 0, 0, 0.7)", // loading 遮罩层颜色
        target: document.querySelector("body"), // 挂载的 DOM 元素
      });
      // 返回的匿名函数
      return () => {
        // 匿名函数返回对象
        return {
          // 控制关闭 Loading 遮罩层
          close: loading.close(),
        };
      };
    },

  },
  components: {
    Head: head,
    tradeInfo,
    Foot: Foot
  },
  created () {
    this.getTrades()
  }
}
</script>

<style lang="less" scped>
*{
    margin: 0;
    padding: 0;
}
li{
    list-style: none;
}
.trade{
  background-color: #F6F6F6;

 .title{
    h2{
      margin: 60px 0 10px 0 ;
      font-size: 28px;
      text-align: center;

    }
  }
  .main{
  width: 90%;
  margin: 30px auto;
  .el-loading{
          background-color: #fff;
          padding: 8px 0;
      }
  .lists{
    width: 100%;

    ul{
      display: flex;
      width: 100%;
      flex-flow:wrap;
      .tradeInfo{
        height: 100%;
        width: 300px;
        margin:  5px;
        background-color: #fff;
        .img{
          width: 100%;
          height: 196px;
          padding: 10px;
          cursor: pointer;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          img{

            height: 100%;
            overflow: hidden;
          }
        }
        .introduce{
          width: 90%;
          margin: 10px auto;

         p{
           margin: 20px 0 10px 0;
           font-size: 16px;
           span{
             display: inline-block;
             padding: 1px 5px;
             border: 1px solid #ff3535;
             color:#ff3535;
             margin: 0 5px;
            font-size: 12px;
           }
          }
        }
        .pri{
          display: flex;
          margin: 20px 0;
          justify-content: space-around;
          p{
            font-size: 20px;
            color:#ff3535;
            line-height: 40px;
            span{
              font-size: 16px;
            }
          }
          .imm{
              color: #fff;
              height: 35px;
              line-height: 35px;
              font-size: 14px;
              display: block;
              cursor: pointer;
              margin: auto 0;
              width: 75px;
              text-align: center;
              background: #df0021;

          }
          .imm:hover{
             color:rgba(255, 255, 255, .9)
          }
        }
      }
    }
  }

}

.bottom{
  margin: 20px 0 ;
  p{
    text-align: center;
    font-size: 24px;
    color:#969090
  }
}

}

</style>
